import { createSlice } from "@reduxjs/toolkit";

const initialRequestListState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  request_listings: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};


export const requestListSlice = createSlice({
  name: "request_listings",
  initialState: initialRequestListState,
  reducers: {
    catchError: (state, action) => {

      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findCommunication
    requestListFetched: (state, action) => {
      // const { request_listings } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.request_listings = action.payload.content || [];
    }
  }
});

export default requestListSlice.reducer;
