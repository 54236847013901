import React from "react";
import { Route } from "react-router-dom";
import { Tabs, Tab, TabContainer } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { useSubheader,LayoutSplashScreen, ContentRoute } from "../../../../../../_metronic/layout";
import {
    Input,
    Select,
    DatePickerField,
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
  } from "../../../controls";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as columnFormatters from "./column-formatters";
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
  } from "../../../../_helpers";
import { Link } from "react-router-dom";
// import AddPage from './AddPage';
// import {Page1} from './components/Page1';
// import {Page2} from './components/Page2';


export function DataList() {
    // const suhbeader = useSubheader();
    // suhbeader.setTitle("Pages List");

    const { actionsLoading, orderForEdit } = useSelector(
        (state) => ({
          actionsLoading: state.orders.actionsLoading,
          orderForEdit: state.orders.orderForEdit,
        }),
        shallowEqual
      );

      const columns = [
        {
          dataField: "id",
          text: "ID",
          sort: true,
          sortCaret: sortCaret,
          
        },
        {
            dataField: "orderId",
            text: "Order ID",
            sort: true,
            sortCaret: sortCaret,
            
        },
        {
            dataField: "orderDate",
            text: "Order Date",
            sort: true,
            sortCaret: sortCaret,
            
        },      
        {
            dataField: "customerName",
            text: "Customer Name",
            sort: true,
            sortCaret: sortCaret,
            
        },        
        {
          dataField: "orderAmount",
          text: "Order Amount",
          sort: true,
          sortCaret: sortCaret,
          
        },
        {
            dataField: "paymentStatus",
            text: "Payment Status",
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.PaymentStatusColumnFormatter,
            
        },
        {
          dataField: "orderStatus",
          text: "Order Status",
          sort: true,
          sortCaret: sortCaret,
          formatter: columnFormatters.StatusColumnFormatter,
          
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
            //   AddPage
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
              minWidth: "100px",
            },
          },
    ];
    let pageListData = [
        {
            id: 1,
            orderId:'ASED3222',
            orderDate:'22/01/2021', 
            customerName: "Anuj Agarwal",           
            orderAmount: "Rs."+2000,
            paymentStatus: 0,
            orderStatus: 0
        },
        {
            id: 2,
            orderId:'ASED3222',
            orderDate:'22/01/2021',
            customerName: "Karan",            
            orderAmount: "Rs."+4000,
            paymentStatus: 1,
            orderStatus: 1
        },
        {
            id: 3,
            orderId:'ASYT3222',
            orderDate:'22/11/2021',  
            customerName:'Arjun',          
            orderAmount: "Rs."+500,
            paymentStatus: 2,
            orderStatus: 2
        },
        {
            id: 4,
            orderId:'ASETR222',
            orderDate:'2/09/2021',   
            customerName: 'Shashank',         
            orderAmount: "Rs."+400,
            paymentStatus: 3,
            orderStatus: 3
        },
        
    ];
    return(
        <> 
             <div className="d-flex flex-row">
                <div className="flex-row-fluid">
                <Card>
                    <CardHeader title="Top 5 Orders">
                        <CardHeaderToolbar>
                            <Link to="/sales/orders">
                            <button type="button"
                                    className="btn btn-primary mr-2">
                                View All Orders
                            </button>
                            </Link>                        
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    striped
                                    hover
                                    bordered={false}
                                    classes="orderListTable table table-head-custom table-vertical-center overflow-hidden"
                                    bootstrap4
                                    remote
                                    keyField="id"
                                    data={pageListData === null ? [] : pageListData}
                                    columns={columns}
                                    pagination={ paginationFactory() }
                                    
                                    
                                    >
                                    <PleaseWaitMessage pageListData={pageListData} />
                                    <NoRecordsFoundMessage pageListData={pageListData} />
                        </BootstrapTable>                            
                    </CardBody>
                </Card>

                </div>
            </div>
        </>  
    );
}