/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";


export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        if(location.pathname.includes(url)) return "menu-item-active";
  else return "";
  //      return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link py-4 px-6" to="/dashboard">
                    <span className="menu-text">Home</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}

            

            <li className={`menu-item menu-item-rel ${getMenuItemActive('/sales')}`}>
                <NavLink className="menu-link py-4 px-6" to="/sales">
                    <span className="menu-text">Sales</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/stock')}`}>
                <NavLink className="menu-link py-4 px-6" to="/stock">
                    <span className="menu-text">Inventory</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/crm')}`}>
                <NavLink className="menu-link py-4 px-6" to="/crm">
                    <span className="menu-text">CRM</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
           
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/accounts')}`}>
                <NavLink className="menu-link py-4 px-6" to="/accounts">
                    <span className="menu-text">Accounts</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/administration')}`}>
                <NavLink className="menu-link py-4 px-6" to="/administration">
                    <span className="menu-text">Portal Management</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/operations')}`}>
                <NavLink className="menu-link py-4 px-6" to="/operations">
                    <span className="menu-text">Operations</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> 
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/vendor')}`}>
                <NavLink className="menu-link py-4 px-6" to="/vendor">
                    <span className="menu-text">Vendor</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
            
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/enquiry')}`}>
                <NavLink className="menu-link py-4 px-6" to="/enquiry">
                    <span className="menu-text">Enquiry</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
{/*         
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/users1')}`}>
                <NavLink className="menu-link py-4 px-6" to="/users1">
                    <span className="menu-text">Users</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>  */}
           
           
            {/*end::1 Level*/}

        </ul>
        {/*end::Header Nav*/}
    </div>;
}
