import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/Stock/_redux/products/productsSlice";
import categoriesReducer from "../app/modules/Stock/_redux/products/categorySlice";

import communicationReducer from "../app/modules/Stock/_redux/products/communicationSlice";
import requestListReducer from "../app/modules/Stock/_redux/products/requestListSlice";
import prodPromotionReducer from "../app/modules/Stock/_redux/products/prodPromotionSlice";
import { ordersSlice } from "../app/modules/Sales/_redux/orders/ordersSlice";
import { storesSlice } from "../app/modules/administration/_redux/setup-organization/storesSlice";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import communicationSlice from "app/modules/Stock/_redux/products/communicationSlice";

import requestListSlice from "../app/modules/Stock/_redux/products/requestListSlice";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  categories: categoriesReducer,
  communication: communicationReducer,
  request_listings: requestListReducer,
  orders: ordersSlice.reducer,
  prodPromotion: prodPromotionReducer,
  storeList: storesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
