import {createSlice} from "@reduxjs/toolkit";

const initialCommunicationsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    communications: null,
    lastError: null
  };
  export const callTypes = {
    list: "list",
    action: "action"
  };


  export const communicationSlice = createSlice({
    name: "communications",
    initialState: initialCommunicationsState,
    reducers: {
      catchError: (state, action) => {
       
        state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
       
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
       // findCommunication
       communicationsFetched: (state, action) => {
        const {  communications } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.communications=  action.payload.communications || [];
      }
    }
  });

  export default communicationSlice.reducer;
  