import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import {BottomHeaderMenuWrapper} from "./bottom-header-menu/BottomHeaderMenuWrapper";

export function Header() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
      bottomMenuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.bottom.menu.self.display"
      ),
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}

      >
        {/* Header Top */}
        <div className="header-top">

          {/*begin::Container*/}
          <div
            className={` ${layoutProps.headerContainerClasses}`}
          >

            <div className="d-none d-lg-flex align-items-center mr-3">
              {/* begin::Left */}
              {/* <div className="d-flex align-items-stretch mr-3"> */}
              {/* begin::Header Logo */}
              <div className="header-logo">
                <Link to="/">
                  <img draggable="false"
                    className="logo-default max-h-40px"
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                  />
                  <img draggable="false"
                    className="logo-sticky max-h-40px"
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                  />
                </Link>
              </div>
              {/* end::Header Logo */}
              {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}

            {/*end::Topbar*/}
            </div>
            {/* end::Left */}

            {/*begin::Topbar*/}
            {/* <Topbar /> */}
            {/*end::Topbar*/}
          </div>
          {/* End sub container */}
        
                         {/*begin::Topbar*/}
            <Topbar />
        </div>
    
        {/*end::Container*/}
        {/* Bottom header */}
        <div className="header-bottom">

          {/*begin::Container*/}
          <div className={` ${layoutProps.headerContainerClasses}`} >

            <div className="d-none d-lg-flex align-items-center mr-3">
            {layoutProps.menuHeaderDisplay && <BottomHeaderMenuWrapper />}
              </div>
            </div>
          </div>

        </div>
        {/* end header top */}
        {/* </div> */}
        {/*end::Header*/}
    </>
  );
}
