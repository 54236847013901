import * as auth from "../app/modules/Auth/_redux/authRedux";

import { backendLogout } from "../app/modules/Auth/_redux/authCrud";
import { toast } from 'react-toastify';
export default function setupAxios(axios, store) {

  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
};
export function handleAxios(axios, store) {

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    console.log(error);
    let errorText = "Something went wrong!"
    if (!error.response) {
      errorText = ("Session-Time-Out or Network-Error!");
    }
    else if (error.response.status === 401) {
      errorText = ("Session timeout, Login again.");
    } else if (error.response.status === 500) {
      errorText = ("Internal server error.");
    } else if (error.response.status === 404) {
      errorText = ("Internal server error.");
    }


    toast.error(errorText, {
      position: toast.POSITION.TOP_RIGHT
    });


    localStorage.clear();
    setTimeout(() => {
      backendLogout()
        .then(({ data }) => {
          window.location.href = "/auth/login";
        })
        .catch(() => {
          window.location.href = "/auth/login";
        });
    }, 1500);
    return error;
  })
};