import axios from "axios";
import { API_URL } from '../../../../utils/constants';
import { createRequestObj, createRequestObjWithoutToken } from '../../../../utils/common';

export const PRODUCTS_URL = "/api/products";
export const getProductCategorySummary = '/store/web/getProductCategorySummary';
export const searchProduct = '/store/searchProduct';
export const searchProductByStore = '/store/getProductListByStore';

export const getProductByIdUrl = '/store/web/getProductById';
export const updateProductByIdUrl = '/store/updateProductById';
export const UPLOAD_PRODUCT = '/merchant/uploadStoreProducts';
export const SEARCH_BY_TAG = '/store/setUpSearchTag';
export const PRODUCT_PROMOTION = '/store/setProductPromotion';
export const AVAILABLE_DISCOUNT = '/store/getAvailableDiscountCodes';
export const ADD_PRODUCT = '/store/addNewProduct';
export const UPDATE_PRODUCT_STATUS = '/store/updateProductSellingStatus';
export const ADD_IMAGE = '/store/addProductImage';
export const DELETE_IMAGE = '/store/deleteProductImage';
export const UPDATE_PRODUCT_BY_ID = '/store/updateProductById';
export const GET_PRODUCT_BY_ID = '/store/web/getProductById';
export const UPDATE_PROD_INVENTORY = '/store/updateProductInventory';
export const GET_DISCOUNT_CODE_LIST = '/store/getAllDiscountCodes';
export const getMessages = '/messaging/getMessages';
export const UPDATE_DISCOUNT_CODE = '/store/updateDiscountCode';



export const requestList = '/merchant/browseSalvageRequest';
//Get all categories
export function fetchProductCategorySummary() {
  return axios.post(`${API_URL}${getProductCategorySummary}`, createRequestObj({}));
}
export function fetcRequestList(queryParams) {
  return axios.post(`${API_URL}${requestList}`, queryParams);
}
//Get all communications
export function fetchCommunications(queryParams) {
  return axios.post(`${API_URL}${getMessages}`, queryParams);
}
// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
  return axios.post(`${API_URL}${PRODUCTS_URL}`, { product });
}

// READ
export function getAllProducts() {
  return axios.get(PRODUCTS_URL);
}

export function getProductById(params) {
  return axios.post(`${API_URL}${getProductByIdUrl}`, null, { params });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProducts(queryParams) {
  console.log('searchProduct', searchProduct)
  return axios.post(`${API_URL}${searchProduct}`, queryParams);
}
export function findProductsBystore(queryParams) {
  let formData = new FormData();
  formData.append('storeId', queryParams.storeId);
  console.log('searchProduct', searchProductByStore)
  return axios.post(`${API_URL}${searchProductByStore}`, formData);
}
// UPDATE => PUT: update the procuct on the server
export function updateProduct(product) {
  // return axios.put(`${PRODUCTS_URL}/${product.id}`, { product });
  return axios.post(`${API_URL}${updateProductByIdUrl}`, { ...product });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    ids,
    status
  });
}

// DELETE => delete the product from the server
export function deleteProduct(productId) {
  return axios.delete(`${PRODUCTS_URL}/${productId}`);
}

// DELETE Products by ids
export function deleteProducts(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
}


export function uploadProductCsv(params, config) {
  return axios.request({
    url: `${API_URL}${UPLOAD_PRODUCT}`,
    method: 'post',
    data: params,
    ...config
  });
}


export function setUpSearchByTag(params) {
  return axios.request({
    url: `${API_URL}${SEARCH_BY_TAG}`,
    method: 'post',
    data: params,
  });
}
export function setupProductPromotionApi(params) {
  return axios.request({
    url: `${API_URL}${PRODUCT_PROMOTION}`,
    method: 'post',
    data: createRequestObjWithoutToken(params),
  });
}

export function getAvailableDiscountApi(params) {
  return axios.request({
    url: `${API_URL}${AVAILABLE_DISCOUNT}`,
    method: 'post',
    data: createRequestObjWithoutToken(params),
  });
}
export function addNewProduct(params) {
  return axios.request({
    url: `${API_URL}${ADD_PRODUCT}`,
    method: 'post',
    data: createRequestObj(params),
  });
}

export function updateProductById(params) {
  return axios.request({
    url: `${API_URL}${UPDATE_PRODUCT_BY_ID}`,
    method: 'post',
    data: createRequestObj(params),
  });
}


export function updateProductSellingStatus(params) {
  return axios.request({
    url: `${API_URL}${UPDATE_PRODUCT_STATUS}`,
    method: 'post',
    data: params,
  });
}
export function addProductImage(params) {
  return axios.request({
    url: `${API_URL}${ADD_IMAGE}`,
    method: 'post',
    data: params,
  });
}

export function deleteProductImage(params) {
  return axios.request({
    url: `${API_URL}${DELETE_IMAGE}`,
    method: 'post',
    data: params,
  });
}

export function getProductByIdAddProduct(params) {
  return axios.request({
    url: `${API_URL}${GET_PRODUCT_BY_ID}`,
    method: 'post',
    params,
  });
}

export function updateProductInventory(params) {
  return axios.request({
    url: `${API_URL}${UPDATE_PROD_INVENTORY}`,
    method: 'post',
    data: params,
  });
}

// Get all diccount codes
export function getAllDiscountCodesByStoreApi(params) {
  return axios.request({
    url: `${API_URL}${GET_DISCOUNT_CODE_LIST}`,
    method: 'get',
    params: params,
  });
}
export function updateDiscountCode(params) {
  return axios.request({
    url: `${API_URL}${UPDATE_DISCOUNT_CODE}`,
    method: 'post',
    data: createRequestObjWithoutToken(params),
  });
}
