import moment from 'moment';
import ClientJS from 'clientjs'

const windowClient = new window.ClientJS();

const fingerPrint = windowClient.getFingerprint()

export function getDateFormat(date) {
    return moment(new Date(date)).format('ll')
}

export function isEmpty(obj) {
    let isempty = false
    const type = typeof obj

    isempty = isempty || !obj
    isempty = isempty || (type === 'undefined') // if it is undefined
    isempty = isempty || (obj === null) // if it is null
    isempty = isempty || (type === 'string' && (obj.trim() === '')) // if the string is empty
    isempty = isempty || (obj === false || obj === 0) // if boolean value returns false
    isempty = isempty || (Array.isArray(obj) && obj.length === 0) // if array is empty
    isempty = isempty || (type === 'object' && Object.keys(obj).length === 0) // if object is empty

    return isempty
}
export function createRequestStoreObj(reqObj) {

    console.log("device fingerprint is", fingerPrint)
    let reqBody = { ...reqObj };

    reqBody.storeId = localStorage.getItem('storeId');
    // reqBody.storeId = '5a32e6e9-964c-41bd-98ff-dbe9e4e21573';

    reqBody.fingerprint = {
        // "createdAt": 0,

        "jsonOtherInfo": localStorage.getItem('jsonOtherInfo') || '',
        // "user_id": 0,
        deviceFingerprint: fingerPrint && fingerPrint.toString(),

    };

    return reqBody
}

export function createRequestObj(reqObj) {


    console.log("device fingerprint is", fingerPrint)
    let reqBody = { ...reqObj };

    reqBody.storeId = localStorage.getItem('storeId');

   
    
    reqBody.token = {
        fingerprint: {
            // "createdAt": 0,

            "jsonOtherInfo": localStorage.getItem('jsonOtherInfo') || '',
            // "user_id": 0,
            deviceFingerprint: fingerPrint && fingerPrint.toString(),

        },
        loginToken: localStorage.getItem('loginId') || ''
    }
    return reqBody
}

export function createRequestObjWithoutToken(reqObj) {


    console.log("device fingerprint is", fingerPrint)
    let reqBody = { ...reqObj };

    reqBody.storeId = localStorage.getItem('storeId');

    reqBody.fingerprint = {
        // "createdAt": 0,

        "jsonOtherInfo": localStorage.getItem('jsonOtherInfo') || '',
        // "user_id": 0,
        deviceFingerprint: fingerPrint && fingerPrint.toString(),

    };
    reqBody.loginToken = localStorage.getItem('loginId') || '';

    return reqBody
}

export function createRequestObjWithoutTokenForStoreAdd(reqObj) {


    console.log("device fingerprint is", fingerPrint)
    let reqBody = { ...reqObj };

    reqBody.deviceFingerprint = {
        // "createdAt": 0,

        "jsonOtherInfo": localStorage.getItem('jsonOtherInfo') || '',
        // "user_id": 0,
        deviceFingerprint: fingerPrint && fingerPrint.toString(),

    };
    reqBody.loginToken = localStorage.getItem('loginId') || '';

    return reqBody
}


export function createRequestObjWithoutStoreId(reqObj) {
    let reqBody = { ...reqObj };

    reqBody.deviceFingerprint = {
        // "createdAt": 0,

        "jsonOtherInfo": localStorage.getItem('jsonOtherInfo') || '',
        // "user_id": 0,
        deviceFingerprint: fingerPrint,

    };
    reqBody.loginToken = localStorage.getItem('loginId') || '';
    return reqBody
}