import { createAsyncThunk } from "@reduxjs/toolkit";
import * as requestFromServer from "./productsCrud";
import {setProductUploadProgress} from './productsSlice';

export const callTypes = {
    list: "list",
    action: "action"
  };

export const uploadProductCsvApi = createAsyncThunk(
	'ncr2/product',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.uploadProductCsv(params, 
                {
                    onUploadProgress: progressEvent => {
                      debugger;
                      console.log(progressEvent.loaded)
                    thunkApi.dispatch(setProductUploadProgress(onprogress));
                    }
                  });
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('There is no data available');
		} catch (error) {
      error.clientMessage = "Error uploading product Csv";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);


export const setUpProductSearchTagApi = createAsyncThunk(
	'ncr2/searchTag',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.setUpSearchByTag(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('There is no data available');
		} catch (error) {
      error.clientMessage = "Error finding product using search tag";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);

export const setupProductPromotionApi = createAsyncThunk(
	'ncr2/productPromotion',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.setupProductPromotionApi(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('There is no data available');
		} catch (error) {
      error.clientMessage = "Error finding product using search tag";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);

export const searchProductApi = createAsyncThunk(
	'ncr2/searchProductApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.findProducts(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('There is no data available');
		} catch (error) {
      error.clientMessage = "Error finding product using product tag";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);


export const getAvailableDiscountApi = createAsyncThunk(
	'ncr2/getAvailableDiscountApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.getAvailableDiscountApi(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('There is no Discount available');
		} catch (error) {
      error.clientMessage = "Error finding products having discount";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);


export const addNewProductApi = createAsyncThunk(
	'ncr2/getAvailableDiscountApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.addNewProduct(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('product not added');
		} catch (error) {
      error.clientMessage = "Error adding products";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);

export const updateProductSellingStatusApi = createAsyncThunk(
	'ncr2/productSellingStatus',
	async (params, thunkApi) => {
		try {
			const response = await  requestFromServer.updateProductSellingStatus(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('error getting selling Status ');
		} catch (error) {
      error.clientMessage = "Error product selling status";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);
export const addProductImageApi = createAsyncThunk(
	'ncr2/addProductImageApi',
	async (params, thunkApi) => {
		try {
			const response = await  requestFromServer.addProductImage(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('error getting product image ');
		} catch (error) {
      error.clientMessage = "Error product image";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);

export const deleteProductImageApi = createAsyncThunk(
	'ncr2/deleteProductImageApi',
	async (params, thunkApi) => {
		try {
			const response = await  requestFromServer.deleteProductImage(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('error getting product image ');
		} catch (error) {
      error.clientMessage = "Error product image";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);

export const updateProductByIdApi = createAsyncThunk(
	'ncr2/productSellingStatus',
	async (params, thunkApi) => {
		try {
			const response = await  requestFromServer.updateProductById(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('error updating product by id ');
		} catch (error) {
      error.clientMessage = "Error updating product ";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);


export const getProductByIdApi = createAsyncThunk(
	'ncr2/productSellingStatus',
	async (params, thunkApi) => {
		try {
			const response = await  requestFromServer.getProductByIdAddProduct(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('error updating product by id ');
		} catch (error) {
      error.clientMessage = "Error updating product ";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);

export const updateProductInventoryApi = createAsyncThunk(
	'ncr2/updateProductInventoryApi',
	async (params, thunkApi) => {
		try {
			const response = await  requestFromServer.updateProductInventory(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('error updating product inventory ');
		} catch (error) {
      error.clientMessage = "Error updating product ";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);
export const updateDiscountCodeApi = createAsyncThunk(
	'ncr2/updateProductInventoryApi',
	async (params, thunkApi) => {
		try {
			const response = await  requestFromServer.updateDiscountCode(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('error updating discount code ');
		} catch (error) {
      error.clientMessage = "Error updating discount  ";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);


// Get discount code list
export const getAllDiscountCodesByStoreApi = createAsyncThunk(
	'ncr2/getAllDiscountCodesByStoreApi',
	async (params, thunkApi) => {
		try {
			const response = await requestFromServer.getAllDiscountCodesByStoreApi(params);
			if (response.data) {
				const { data } = response;
				// thunkApi.dispatch(getObjectDetails())
				// let firstParent = Array.isArray(data) && data.find(ele => !ele.parent_id) || {};
				// thunkApi.dispatch(getObjectDetails(firstParent?._id))
				return data;
			}
			return thunkApi.rejectWithValue('There is no Discount available');
		} catch (error) {
      error.clientMessage = "Error finding products having discount";
      return thunkApi.rejectWithValue({ error, callType: callTypes.action });
    }
	}
);

