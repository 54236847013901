import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import {DashboardPage1} from "./modules/administration/pages/dashboard-page/DashboardPage1";
// import{AdministrationPage} from './modules/administration/pages/administrationPage';


// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
const ECommercePage = lazy(() =>
import("./modules/ECommerce/pages/eCommercePage")
);
const AdministrationPage = lazy(() =>
  import("./modules/administration/pages/administrationPage")
);
const CRMPage = lazy(() =>
  import("./modules/crm/pages/crmPage")
);
const OperationsPage = lazy(() =>
  import("./modules/operations/pages/operationsPage")
);
const VendorContractsPage = lazy(() =>
import("./modules/vendorContracts/pages/vendorContractsPage")
);

const StockPage = lazy(() =>
import("./modules/Stock/pages/StockPage")
);

const AccountsPage = lazy(() =>
import("./modules/Accounts/pages/AccountsPage")
);
const EnquiryPage = lazy(() =>
import("./modules/Enquiry/pages/EnquiryPage")
);
const SalesPage = lazy(() =>
import("./modules/Sales/pages/SalesPage")
);
// const UsersPage = lazy(() =>
// import("./modules/Users/pages/UsersPage")
// );
// const ProductsPage =lazy(() =>
// import("./modules/Products/pages/Products/ProductsPage")
// );
// const OrdersPage= lazy(() =>
// import("./modules/Orders/pages/OrdersPage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    // <Redirect exact from="/" to="/administration/users-dashboard"/>
                    <Redirect exact from="/" to="/sales/orders"/>
                }
                <ContentRoute path="/administration/users-dashboard" component={DashboardPage1}/>

                {/* Administration */}
                <Route path="/administration" component={AdministrationPage}/>                

                {/* CRM */}
                <Route path="/crm" component={CRMPage}/>  

                {/* Operations */}
                <Route path="/operations" component={OperationsPage}/>  

                 {/* Vendor */}
                 <Route path="/vendor" component={VendorContractsPage}/>  

                 {/* Stock */}
                 <Route path="/stock" component={StockPage}/>  

                {/* Accounts */}
                <Route path="/accounts" component={AccountsPage}/>  
                {/* Enquiry */}
                <Route path="/enquiry" component={EnquiryPage}/>   
                {/* Sales */}
                <Route path="/sales" component={SalesPage}/>   
                 {/* Users */}
                 {/* <Route path="/users1" component={UsersPage}/>    */}
                {/* Products */}
                {/* <Route path="/products" component={ ProductsPage}/>   */}
                {/* Products */}
                {/* <Route path="/orders" component={ OrdersPage}/>   */}
                

                {/* <ContentRoute path="/builder" component={BuilderPage}/> */}
                {/* <ContentRoute path="/my-page" component={MyPage}/> */}
                {/* <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
