import {createSlice} from "@reduxjs/toolkit";

const initialCategoriesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    productCategories: null,
    lastError: null
  };
  export const callTypes = {
    list: "list",
    action: "action"
  };


  export const categoriesSlice = createSlice({
    name: "categories",
    initialState: initialCategoriesState,
    reducers: {
      catchError: (state, action) => {
        state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
  
      // findCategories
      categoriesFetched: (state, action) => {
        const {  productCategories } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.productCategories = productCategories.Categories || productCategories || [];
      }
    }
  });

  export default categoriesSlice.reducer;
  