/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import './../header.css';

export function BottomHeaderMenu({ layoutProps }) {
    const location = useLocation();
    const bottomMenus = [
        {
            menu: 'Home',
            // subMenu: [{ name: 'Dashboard', link: '/dashboard' },
            // { name: 'Stats', link: '/stats' },
            // { name: 'Help Center', link: '/help-center' }]
        },
        {
            menu: 'Administration',
            subMenu: [
                // { name: 'Dashboard', link: '/administration/users-dashboard' },
                // { name: 'Users', link: '/users1/user-list' },
                // { name: 'Store Overview', link: '/administration/users-storeoverview' },
                // { name: 'Store Management', link: '/administration/users-storemgmt' },

                // { name: 'Portal Management', link: '/administration/users-portalmgmt' },
            ]
        },
        {
            menu: 'CRM',
            subMenu: [
                { name: 'Users', link: '/crm/users' },
                { name: 'User Stores', link: '/crm/stores-list' },
                { name: 'Request Listing', link: '/crm/request-listing' },
                { name: 'Contact Us', link: '/crm/contactUs' },
                { name: 'Communication', link: '/crm/communication' },
                { name: 'Dashboard', link: '/crm/crm-dash' },
               
                // { name: 'Enquiry Item', link: '/crm/crm-enquiry-item' },
                // { name: 'Leads', link: '/crm/crm-leads' },
                // { name: 'Deals', link: '/crm/crm-deals' },
                // { name: 'Documents', link: '/crm/crm-docs' }
            ]
        },
        {
            menu: 'Operations',
            subMenu: [
                { name: 'Dashboard', link: '/operations/operations-dash' },
                { name: 'Ticketing System', link: '/operations/ticketing-system' },
            ]
        },
        {
            menu: 'Vendor',
            subMenu: [
                { name: 'Vendor List', link: '/vendor/vendor-list' },
                { name: 'Ledger', link: '/vendor/vendor-ledger' },
                { name: 'Vendor Registration', link: '/vendor/vendor-registration' },
                { name: 'Vendor Outstanding Payment', link: '/vendor/vendor-payments' },
                { name: 'Vendor KYC', link: '/vendor/vendor-kyc' },
                { name: 'Dashboard', link: '/vendor/vendor-dash' },
            
            ]
        },
        {
            menu: 'Stock',
            subMenu: [
                { name: 'Products List', link: '/stock/products' },
                { name: 'Cat/Subcategory', link: '/stock/catSubCat-list' },
                { name: 'Manufacturers', link: '/stock/manufacturer-list' },
                { name: 'Bulk Orders', link: '/stock/bulk-order' },
                { name: 'Dashboard', link: '/stock/stock-dash' },
             
                // { name: 'Ledger', link: '/stock/stock-ledger'},
                // { name: 'Analysis', link: '/stock/stock-analysis' },
                // { name: 'Valuation(FIFO)', link: '/stock/stock-valuation' },
                // { name: 'Stock Adjustment', link: '/stock/stock-adjustment' },
                // { name: 'Stock Journal', link: '/stock/stock-journal' },
                // { name: 'Warehouse Transfer', link: '/stock/stock-warehouseTransfer' },
            ]
        },
        {
            menu: 'Accounts',
            subMenu: [
                // { name: 'Dashboard', link: '/accounts/accounts-dash'},
                // { name: 'Receipt Analysis', link: '/accounts/accounts-receipt-analysis' },
                // { name: 'Receipts By Mode', link: '/accounts/accounts-receipt-modes' }

            ]
        },
        {
            menu: 'Enquiry',
            subMenu: [
                { name: 'Item', link: '/enquiry/enquiry-item' }

            ]
        },
        {
            menu: 'Sales',
            subMenu: [
                { name: 'Bid Orders', link: '/sales/bidding' },
                { name: 'Cart Orders', link: '/sales/orders' },
               
                { name: 'Sales Summary', link: '/sales/sales-daySummary' },
                { name: 'Dashboard', link: '/sales/sales-dash' },
            
                // { name: 'By Mode', link:'/sales/sales-byModePage' }, 
                // { name: 'Counter Summary', link:'/sales/sales-counterSummary' },
                // { name: 'Analysis', link:'/sales/sales-analysis' },
                // { name: 'Margin', link:'/sales/sales-marginPage' },
                // { name: 'Scheme Vs Sales', link:'/sales/sales-scheme' },
                // { name: 'Sales With Stock', link:'/sales/sales-stock' },

            ]
        },
        // {menu: 'Users',
        //     subMenu: [
        //     { name: 'Users List', link: '/users1/user-list' },
        //     { name: 'Employee Portal', link: '/users1/employeePortal' }


        //     ]
        // },
        // {menu: 'Products',
        //     subMenu: [
        //     { name: 'Products List', link: '/products/products-list' } ,           
        //     ]
        // },


    ]
    let defaultBgImage = "bg/projectBg/1.jpg";
  
    let menuSelected = 'Home';
    if (location.pathname.includes('users') || location.pathname.includes('user-list')) {
        menuSelected = 'Administration'
        document.body.style.backgroundImage = "url('https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')";
    }
    if (location.pathname.includes('crm')) {
        menuSelected = 'CRM'
        document.body.style.backgroundImage = "url('https://images.unsplash.com/photo-1494500764479-0c8f2919a3d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')";
 
    }

    if (location.pathname.includes('operations')) {
        menuSelected = 'Operations'
        document.body.style.backgroundImage = "url('https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1342&q=80')";
 
    }
    if (location.pathname.includes('vendor')) {
        menuSelected = 'Vendor'
        document.body.style.backgroundImage = "url('https://images.unsplash.com/photo-1511884642898-4c92249e20b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')";
 
    }
    if (location.pathname.includes('stock') || location.pathname.includes('product')) {
        menuSelected = 'Stock'
        document.body.style.backgroundImage = "url('https://images.unsplash.com/photo-1470770841072-f978cf4d019e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')";
 
    }
    if (location.pathname.includes('accounts')) {
        menuSelected = 'Accounts'
        document.body.style.backgroundImage = "url('https://image.freepik.com/free-photo/milford-sound-new-zealand-travel-destination-concept_53876-42945.jpg')";
    }
    
    if (location.pathname.includes('sales')) {
        menuSelected = 'Sales'
        document.body.style.backgroundImage = "url('https://cdn.pixabay.com/photo/2016/05/05/02/37/sunset-1373171__480.jpg')";
    }
    // if(location.pathname.includes('users1')){
    //     menuSelected = 'Users'
    // }
    // if(location.pathname.includes('products')){
    //     menuSelected = 'Products'
    // }

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "bottom-menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {
                bottomMenus.filter(val => val.menu === menuSelected).map(val => {
                    const { subMenu = [] } = val;
                    return subMenu.map(botVal => (
                        <li className={`bottom-menu-item bottom-menu-item-rel ${getMenuItemActive(botVal.link)}`}>
                            <NavLink className="bottom-menu-link py-5  " to={botVal.link}>
                                <span className="bottom-menu-text">{botVal.name}</span>
                                {layoutProps.rootArrowEnabled && (<i className="bottom-menu-arrow" />)}
                            </NavLink>


                            {/* <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                            <ul className="menu-subnav">
                                
                                <li
                                    className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material/inputs')}`}
                                    data-menu-toggle="hover"
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                                        <span className="svg-icon menu-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/PenAndRuller.svg")} />
                                        </span>
                                        <span className="menu-text">
                                            Inputs
                                        </span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                        <ul className="menu-subnav">
                                            
                                            <li className={`menu-item ${getMenuItemActive('/google-material/inputs/autocomplete')}`}>
                                                <NavLink className="menu-link" to="/google-material/inputs/autocomplete">
                                                    <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                    <span className="menu-text">Autocomplete</span>
                                                </NavLink>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div> */}

                        </li>
                    ))
                })
            }
            {/*end::1 Level*/}



            {/*begin::1 Level*/}
            {/* <li className={`bottom-menu-item bottom-menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="bottom-menu-link py-5  " to="/dashboard">
                    <span className="bottom-menu-text">Dashboard</span>
                    {layoutProps.rootArrowEnabled && (<i className="bottom-menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`bottom-menu-item bottom-menu-item-rel ${getMenuItemActive('/users-page')}`}>
                <NavLink className="bottom-menu-link py-5  " to="/users-page">
                    <span className="bottom-menu-text">Users</span>
                    {layoutProps.rootArrowEnabled && (<i className="bottom-menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`bottom-menu-item bottom-menu-item-rel ${getMenuItemActive('/users-setuporg')}`}>
                <NavLink className="bottom-menu-link py-5  " to="/users-setuporg">
                    <span className="bottom-menu-text">Setup Organization</span>
                    {layoutProps.rootArrowEnabled && (<i className="bottom-menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`bottom-menu-item bottom-menu-item-rel ${getMenuItemActive('/crm-leads')}`}>
                <NavLink className="bottom-menu-link py-5  " to="/crm-leads">
                    <span className="bottom-menu-text">Leads</span>
                    {layoutProps.rootArrowEnabled && (<i className="bottom-menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`bottom-menu-item bottom-menu-item-rel ${getMenuItemActive('/crm-deals')}`}>
                <NavLink className="bottom-menu-link py-5  " to="/crm-deals">
                    <span className="bottom-menu-text">Deals/Contract</span>
                    {layoutProps.rootArrowEnabled && (<i className="bottom-menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`bottom-menu-item bottom-menu-item-rel ${getMenuItemActive('/crm-docs')}`}>
                <NavLink className="bottom-menu-link py-5  " to="/crm-docs">
                    <span className="bottom-menu-text">Documents</span>
                    {layoutProps.rootArrowEnabled && (<i className="bottom-menu-arrow" />)}
                </NavLink>
            </li> */}
            {/*end::1 Level*/}

        </ul>
        {/*end::Header Nav*/}
    </div>;
}
