import React, { Component } from 'react';
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu2 } from "../../dropdowns";
import Chart from 'react-apexcharts'

class AdminDonutChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        labels: ['Sowfresh Juice', 'Iron Candles', 'Bedsheet', 'Haldi Powder', 'Kitchen Kit'],
        plotOptions: {
            pie: {
              size: 200,
              donut: {
                size: '65%'
              }
            }
          }
      },
      series: [44, 55, 41, 17, 15],
      labels: ['Sowfresh Juice', 'Iron Candles', 'Bedsheet', 'Haldi Powder', 'Kitchen Kit']
    }
  }

  render() {

    return (
        <div className={`card card-custom `}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-dark">
                    Highest Ordered Products (current month)
                </h3>
                <div className="card-toolbar">
                <Dropdown className="dropdown-inline" alignRight>
                <Dropdown.Toggle
                    className="btn btn-text-white btn-hover-white btn-sm btn-icon border-0"
                    variant="transparent"
                    id="dropdown-toggle-top"
                    as={DropdownCustomToggler}
                >
                    <i className="ki ki-bold-more-hor" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <DropdownMenu2 />
                </Dropdown.Menu>
                </Dropdown>
            </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body d-flex flex-column p-0">            
                <div className="donut">
                    <Chart options={this.state.options} series={this.state.series} type="donut" width="380" />
                </div>
            </div>
        </div>
      
    );
  }
}

export default AdminDonutChart;