import { createAsyncThunk } from "@reduxjs/toolkit";
import { createRequestObjWithoutToken, createRequestObjWithoutTokenForStoreAdd } from "app/utils/common";
import * as requestFromServer from "./storesCrud";

export const callTypes = {
    list: "list",
    action: "action"
};


export const getAllStoresApi = createAsyncThunk(
    'ncr2/getAllStoresApi',
	async (params, thunkApi) => {
        try{
            const response = await requestFromServer.getAllStoresApi(params);
            if(response.data){
                const {data} = response;
                return data;
            }
            return thunkApi.rejectWithValue('There is no store available');

        }catch(error){
            error.clientMessage = "Error finding stores";
            return thunkApi.rejectWithValue({ error, callType: callTypes.action });
        }
    }
);

export const addNewStoreApi = createAsyncThunk(
    'ncr2/getAllStoresApi',
	async (params, thunkApi) => {
        try{
            const response = await requestFromServer.addNewStore(createRequestObjWithoutTokenForStoreAdd(params));
            if(response.data){
                const {data} = response;
                return data;
            }
            return thunkApi.rejectWithValue('There is no store available');

        }catch(error){
            error.clientMessage = "Error adding stores";
            return thunkApi.rejectWithValue({ error, callType: callTypes.action });
        }
    }
);

export const updateStoreStatusApi = createAsyncThunk(
    'ncr2/udateStoreStatusApi',
	async (params, thunkApi) => {
        try{
            const response = await requestFromServer.updateStoreStatus(params);
            if(response.data){
                const {data} = response;
                return data;
            }
            return thunkApi.rejectWithValue('error updating status');

        }catch(error){
            error.clientMessage = "error updating status";
            return thunkApi.rejectWithValue({ error, callType: callTypes.action });
        }
    }
);
export const updatePaymentGatewayApi = createAsyncThunk(
    'ncr2/updatePaymentGatewayApi',
	async (params, thunkApi) => {
        try{
            const response = await requestFromServer.updatePaymentGateway(params);
            if(response.data){
                const {data} = response;
                return data;
            }
            return thunkApi.rejectWithValue('error updating status');

        }catch(error){
            error.clientMessage = "error updating status";
            return thunkApi.rejectWithValue({ error, callType: callTypes.action });
        }
    }
);

export const addNewPage = createAsyncThunk(
    'ncr2/addNewPage',
	async (params, thunkApi) => {
        try{
            const response = await requestFromServer.addNewPage(params);
            if(response.data){
                const {data} = response;
                return data;
            }
            return thunkApi.rejectWithValue('error adding new page');

        }catch(error){
            error.clientMessage = "error adding new page";
            return thunkApi.rejectWithValue({ error, callType: callTypes.action });
        }
    }
);

export const addEmployeeToStoreApi = createAsyncThunk(
    'ncr2/udateStoreStatusApi',
	async (params, thunkApi) => {
        try{
        const response = await requestFromServer.addEmployeeRToStore(params);
            if(response.data){
                const {data} = response;
                return data;
            }
            return thunkApi.rejectWithValue('error adding employee');

        }catch(error){
            error.clientMessage = "error adding employee";
            return thunkApi.rejectWithValue({ error, callType: callTypes.action });
        }
    }
);