import React, {Suspense, lazy} from "react";
import {Link } from "react-router-dom";
import {DataList} from './DataList';

export function OrdersTable({ className }) {
    return(
        <>
        {/* <h1>
            Vendor ledger
        </h1> */}
            <div className="row">
                <div className="col-lg-12 text-right">
                    {/* <Link to="/users1/edit-user">
                        <button type="button"
                            className="btn btn-light"                        
                        >
                            Edit User
                        </button>
                    </Link> */}
                </div>
                {/* <div className="col-lg-4">
                    <MixedWidget4 className="gutter-b card-stretch" />
                </div>
                <div className="col-lg-4">
                <StatsWidget11
                        className="gutter-b"
                        symbolShape="circle"
                        baseColor="danger"
                    />
                     <StatsWidget10
                        className="gutter-b"
                        symbolShape="circle"
                        baseColor="info"
                    />
                </div>
                <div className="col-lg-4">
                    <LeadsWidget1 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-12">
                    <LeadsTableData className="card-stretch gutter-b"/>
                </div> */}
                <div className={`col-lg-12 ${className}`}>
                    <DataList/>
                </div>
               
            </div>
        </>  
    );
}