import axios from "axios";
import { API_URL } from '../../../utils/constants';

export const LOGIN_URL = "/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const LOGOUT_URL = "/store/logout";
export const ME_URL = "api/me";

export function login(usernameOrEmail, storeId, password) {
  return axios.post(`${API_URL}${LOGIN_URL}`, { usernameOrEmail, storeId, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}


export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}
export function backendLogout() {
  return axios.get(`${API_URL}${LOGOUT_URL}`);
}
export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(`${API_URL}${ME_URL}`);
}
