import {createSlice} from "@reduxjs/toolkit";
import {getAvailableDiscountApi} from './productsApi';


const initProdPromotions = {
  isFetching: false,
  prodPromotions: {}


  };


  export const prodPromotionSlice = createSlice({
    name: "prodPromotion",
    initialState: initProdPromotions,
    reducers: {

    },
    extraReducers: {
      [getAvailableDiscountApi.pending]: (state, action) => {
        state.isFetching = true;
      },
      [getAvailableDiscountApi.fulfilled]: (state,action) => { 
        state.isFetching = false;
        state.prodPromotions = action.payload;

      },
      [getAvailableDiscountApi.rejected]: (state, action) => {
        state.isFetching = false;
      },
    }
  });

  export default prodPromotionSlice.reducer;
