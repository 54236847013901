/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { toAbsoluteUrl } from "../../../_helpers";

export function UserProfileWidget1({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">User Profile</h3>

          <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-ver" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          <div className="d-flex align-items-center pl-0 pt-5 pb-5 pr-5">
              <div className="symbol symbol-50 symbol-light  mt-1 mr-2">
                    <span className="symbol-label">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/avatars/014-girl-7.svg")}
                        className="h-75 align-center  "
                      ></SVG>
                    </span>
                  </div>
                 
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <a
                      href="#"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                    >
                      John Doe
                    </a>
                    
                    <span className="text-muted font-weight-bold d-none">Due in 2 Days</span>
                  </div>
                  <span className="font-weight-bolder text-warning py-1 font-size-lg">
                   Profile Completion: 28%
                  </span>
          </div>
          <div class="row">
            
              <div class="col-md-6">
                  <div className="d-flex align-items-center pl-0 pt-5 pb-5 pr-5">
                  <div className="symbol symbol-50 symbol-light  mt-1 mr-2">
                        <span className="symbol-label">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/misc/015-telegram.svg")}
                            className="h-75 align-center"
                          ></SVG>
                        </span>
                      </div>
                    
                      <div className="d-flex flex-column flex-grow-1 mr-2">
                        
                        <p className="font-weight-bold text-dark-75 font-size-lg m-0">
                          <a className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                      href="mailto:abc@johndoe.com" 
                                      target="_blank"> abc@johndoe.com</a>
                            </p>
                            <span className="text-muted font-weight-bold d-none">Due in 2 Days</span>
                          </div>                   
                    </div>
              </div>
              <div class="col-md-6">
                  <div className="d-flex align-items-center pl-0 pt-5 pb-5 pr-5">
                  <div className="symbol symbol-50 symbol-light  mt-1 mr-2">
                        <span className="symbol-label">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/icons/communication/Incoming-call.svg")}
                            className="h-75 align-center"
                          ></SVG>
                        </span>
                      </div>
                    
                      <div className="d-flex flex-column flex-grow-1 mr-2">
                        
                        <p className="font-weight-bold text-dark-75 font-size-lg m-0">
                        <a className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                  href="tel:0000999999" 
                                  target="_blank"> 0000999999</a>
                        </p>
                        <span className="text-muted font-weight-bold d-none">Due in 2 Days</span>
                      </div>                   
                </div>
              </div>

          </div>

        



          

          

          

          
        </div>
      </div>
    </>
  );
}
