import React, {Suspense, lazy} from "react";

// import {ListsWidget10, ListsWidget11, AdvanceTablesWidget1, MixedWidget6, MixedWidget10,
//     MixedWidget11, MixedWidget4, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, 
//     TilesWidget12, TilesWidget13, TilesWidget14, BaseTablesWidget2,UsersListWidget,UserActivityListWidget1,
//     UserProfileWidget1, leadWidget1,StatsWidget11,StatsWidget10 } from "../../../../../_metronic/_partials/widgets";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
// import {LeadsWidget1} from "../../../../../_metronic/_partials/widgets/lead-widgets/leads-widget1";
//import {LeadsTableData} from "../../../../../_metronic/_partials/widgets/lead-widgets/lead-table-data";

import {AdminMixedWidget4} from "../../../../../_metronic/_partials/widgets/administration-widgets/AdminMixedWidget4";
import { AdminStatsWidget10 } from "../../../../../_metronic/_partials/widgets/administration-widgets/AdminStatsWidget10";
import {AdminStatsWidget11} from "../../../../../_metronic/_partials/widgets/administration-widgets/AdminStatsWidget11";
import { LeadsWidget1 } from "../../../../../_metronic/_partials/widgets/administration-widgets/leads-widget1";
import { LeadsTableData } from "../../../../../_metronic/_partials/widgets/administration-widgets/lead-table-data";
import AdminDonutChart from "../../../../../_metronic/_partials/widgets/administration-widgets/AdminDonutChart";
import AdminRadialChart from "../../../../../_metronic/_partials/widgets/administration-widgets/AdminRadialChart";
import {OrdersTable} from "../../../../../_metronic/_partials/widgets/administration-widgets/orderTableData/OrdersTable";

export function DashboardPage1() {
    return(
        <>  
            <div className="row">
                <div className="col-lg-4">
                    <AdminMixedWidget4 className="gutter-b card-stretch" />
                </div>
                <div className="col-lg-4">
                    <AdminStatsWidget11
                            className="gutter-b"
                            symbolShape="circle"
                            baseColor="danger"
                        />
                     <AdminStatsWidget10
                        className="gutter-b"
                        symbolShape="circle"
                        baseColor="info"
                    />
                </div>
                <div className="col-lg-4">
                    <LeadsWidget1 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-6">
                    <AdminDonutChart className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-6">
                    <AdminRadialChart className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-12">
                    {/* <LeadsTableData className="card-stretch gutter-b"/> */}
                    <OrdersTable className="card-stretch gutter-b mt-8"/>
                </div>
               
            </div>
        
        
        </>
    );
}