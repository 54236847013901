import React, { Component } from 'react';
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu2 } from "../../dropdowns";
import Chart from 'react-apexcharts'

class AdminRadialChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        labels: ['Males', 'Females', 'Others'],
        plotOptions:{
          radialBar: {
            dataLabels: {
              show: true,
              name: {
                  show: true,
              },
              value: {
                show: true,
                formatter: function (val) {
                  return val
                }
              },
              total: {
                show: true,
                label: 'TOTAL',
                formatter: function (w) {
                  return parseInt(w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0))
                }
              }
            }
          }
        }
      },
      series: [144, 255, 41],
      labels: ['Males', 'Females', 'Others']
    }
  }

  render() {

    return (
        <div className={`card card-custom `}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-dark">
                    Customers
                </h3>
                <div className="card-toolbar">
                <Dropdown className="dropdown-inline" alignRight>
                <Dropdown.Toggle
                    className="btn btn-text-white btn-hover-white btn-sm btn-icon border-0"
                    variant="transparent"
                    id="dropdown-toggle-top"
                    as={DropdownCustomToggler}
                >
                    <i className="ki ki-bold-more-hor" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <DropdownMenu2 />
                </Dropdown.Menu>
                </Dropdown>
            </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body d-flex flex-column p-0">            
                <div className="donut">
                    <Chart options={this.state.options} series={this.state.series} type="radialBar" width="380" />
                </div>
            </div>
        </div>
      
    );
  }
}

export default AdminRadialChart;