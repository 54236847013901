import axios from "axios";
import { API_URL } from '../../../../utils/constants';
import { createRequestObj, createRequestObjWithoutToken } from '../../../../utils/common';
import { createRequestObjWithoutTokenForStoreAdd } from '../../../../utils/common';

export const GET_STORES = '/store/web/getStoreList';
export const ADD_STORES = '/merchant/addNewStore';
export const UPDATE_STATUS = '/merchant/updateStoreStatus';
export const ADD_EMPLOYEE = '/store/addEmployeeToStore';
export const UPDATE_PAYMENT_GATEWAY = '/store/updatePaymentGateway';

export const AddNewPage = '/store/addNewPage';

// Get all stores
export function getAllStoresApi(params) {
    return axios.request({
        url: `${API_URL}${GET_STORES}`,
        method: 'get',
        params: params,
    });
}

export function addNewStore(params) {
    return axios.request({
        url: `${API_URL}${ADD_STORES}`,
        method: 'post',
        data: params,
    });
}
export function updateStoreStatus(params) {
    return axios.request({
        url: `${API_URL}${UPDATE_STATUS}`,
        method: 'post',
        data: params,
    });
}
export function updatePaymentGateway(params) {
    return axios.request({
        url: `${API_URL}${UPDATE_PAYMENT_GATEWAY}`,
        method: 'post',
        data: createRequestObj(params),
    });
}

export function addNewPage(params) {
    return axios.request({
        url: `${API_URL}${AddNewPage}`,
        method: 'post',
        data: createRequestObj(params),
    });
}


export function addEmployeeRToStore(params) {
    return axios.request({
        url: `${API_URL}${ADD_EMPLOYEE}`,
        method: 'post',
        data: createRequestObjWithoutToken(params),
    });
}