import React, { useState, useEffect } from "react";
import { getStoreKey } from "../../ECommerce/_redux/customers/customersCrud";


import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

import { toast } from 'react-toastify';
import {
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  NativeSelect
} from "@material-ui/core";
import { getAllStoresApi } from '../../../modules/administration/_redux/setup-organization/storesApi'
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  usernameOrEmail: "",
  password: "",
  storeId: null
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  let queryParams = {
    loginToken: localStorage.getItem('loginId') || ''
  }
  useEffect(() => {
    dispatch(getAllStoresApi(queryParams));

  }, [dispatch]);
  const { isFetching = false, storesList = [] } = useSelector((state) => state.storeList);


  //  localStorage.setItem('storeId', storeId);


  const LoginSchema = Yup.object().shape({
    usernameOrEmail: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    storeId: Yup.string().nullable().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.usernameOrEmail, values.storeId, values.password)
          .then(({ data }) => {
            const { accessToken } = data;
            let user = {
              ...data
            }

            console.log(user);
            localStorage.setItem('loginId', user.loginToken);
            // alert(localStorage.getItem('loginId'))

            let isAccessGranted = false;
            if (user.userRole.length) {
              for (let index = 0; index < user.userRole.length; index++) {
                let role = user.userRole[index].name;
                if (role === 'ROLE_MERCHANT' || role === 'PLATFORM_OWNER' || role === 'ROLE_ADMIN') {
                  isAccessGranted = true;
                }
              }

            }

            if (isAccessGranted) {

              getStoreKey(accessToken)
                .then(({ data }) => {
                  disableLoading();
                  setSubmitting(false);
                  if (data.storeId && (values.storeId === data.storeId)) {

                    localStorage.setItem('storeId', data.storeId);
                    delete user.accessToken
                    props.login(accessToken, user);
                    toast.success('Welcome Admin!', {
                      position: toast.POSITION.TOP_RIGHT
                    });




                  } else {
                    toast.error('No access to selected store.', {
                      position: toast.POSITION.TOP_RIGHT
                    });

                  }
                });



            } else {
              toast.error('Admin not found.', {
                position: toast.POSITION.TOP_RIGHT
              });

            }







          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        {/* <p className="text-muted font-weight-bold">
          Enter your username and password
        </p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text text-center">
              Enter your username and password below to continue

            </div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Username or Email Id"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "usernameOrEmail"
            )}`}
            name="usernameOrEmail"
            {...formik.getFieldProps("usernameOrEmail")}
          />
          {formik.touched.usernameOrEmail && formik.errors.usernameOrEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.usernameOrEmail}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">

          <select
            onChange={(e) => formik.setFieldValue('storeId', e.target.value)}
            name="storeId" placeholder="Select Store" className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "storeId"
            )}`}
            {...formik.getFieldProps("storeId")}

          >
            <option value={null}>Select Store</option>
            {storesList.map(store => (
              <option value={store.storeKey} className="text-muted">{store.storeName}</option>

            ))}
          </select>
          {formik.touched.storeId && formik.errors.storeId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.storeId}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
