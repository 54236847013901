import {createSlice} from "@reduxjs/toolkit";
import {getAllStoresApi} from './storesApi';


const initStores = {
  isFetching: false,
  storesList: []


};


export const storesSlice = createSlice({
    name: "stores",
    initialState: initStores,
    reducers: {

    },
    extraReducers: {
      [getAllStoresApi.pending]: (state, action) => {
        state.isFetching = true;
      },
      [getAllStoresApi.fulfilled]: (state,action) => { 
        state.isFetching = false;
        state.storesList = action.payload;

      },
      [getAllStoresApi.rejected]: (state, action) => {
        state.isFetching = false;
      },
    }
});

export default storesSlice.reducer;